import Layout from "@/views/layouts/main.vue"
import { todoGetters } from "@/state/helpers"
import Datepicker from 'vue3-datepicker'
import { GoogleMap, Marker } from "vue3-google-map"
import markerIcon from '@/assets/img/location24x36.png'
import PnlFacturacion from './PnlFacturacion.vue'
import ClienteSrv from '@/services/ClienteSrv.js'
import ContratoSrv from '@/services/ContratoSrv.js'
import ArchivoContratosSrv from '@/services/ArchivoContratosSrv.js'
import CpeSrv from '@/services/CpeSrv.js'
import DhSrv from '@/services/DhSrv.js'
import RouterSrv from '@/services/RouterSrv.js'
import ProductoSrv from '@/services/ProductoSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import SitioSrv from '@/services/SitioSrv.js'
import MdlCambiarPeriodo from './MdlCambiarPeriodo.vue'
import MdlHistorialPeriodos from './MdlHistorialPeriodos.vue'
import MdlThroughPut from '../network/cpes/MdlThroughput.vue'
import MdlThroughPutNuevo from '../network/cpes/MdlThroughput-nuevo.vue'
import EstadoCpesSrv from '@/services/EstadoCpesSrv.js'
import MarcaCpesSrv from '@/services/MarcaCpesSrv.js'
import ModeloCpesSrv from '@/services/ModeloCpesSrv.js'
import ModeloDhsSrv from '@/services/ModeloDhsSrv.js'
import EstadoDhsSrv from '@/services/EstadoDhsSrv.js'
import MarcaDhsSrv from '@/services/MarcaDhsSrv.js'
import TipoDhsSrv from '@/services/TipoDhsSrv.js'
import MdlVisorArchivo from "./MdlVisorArchivo.vue"
import { SimpleBar } from "simplebar-vue3"
var ipaddr = require('ipaddr.js')
import Swal from 'sweetalert2'
import moment from 'moment'
import API from '@/API.js'

export default {
  name: 'EdicionContrato',
  components: {
    Layout, Datepicker, PnlFacturacion,
    MdlCambiarPeriodo, MdlHistorialPeriodos,
    MdlVisorArchivo, MdlThroughPut, MdlThroughPutNuevo,
    SimpleBar, GoogleMap, Marker
  },
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      modo: 'nuevo',
      piva: 0,
      informacion_fecha_corte_servicio: 0,
      
      markerIcon:markerIcon,
      mapaZoom: 12,
      posicionInicial: {
        lat: 0,
        lng: 0
      },
      lat_lng: 0,
      bloquear_lat_lng: true,
      styles_dark: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { invert_lightness: true },
            { saturation: 10 },
            { lightness: 30 },
            { gamma: 0.5 },
            { hue: "#435158" }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 }
          ]
        }
      ],
      styles_light:[
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 20 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 40 }
          ]
        },{
          featureType: "water",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -10 },
            { lightness: 30 }
          ]
        },{
          featureType: "landscape.man_made",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 10 }
          ]
        },{
          featureType: "landscape.natural",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 60 }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        }
      ],
      cliente: {
        nombre: '',
        apellido_paterno: '',
        apellido_materno: ''
      },
      contrato: {
        id: null,
        id_cliente: null,
        dia_pago: 1,
        n_dias_corte: 1,
        n_dias_generar_factura: 1,
        hora_corte: '12:00:00',
        fecha_instalacion: null,
        fecha_vencimiento: null,
        fecha_para_pago: null,
        fecha_corte_servicio: null,
        fecha_generar_nueva_factura: null,
        nota: '',
        nota_cancelacion: '',
        latitud: 0,
        longitud: 0,
        cargo: 0,
        descuento: 0,
        prepago: 1,
        id_tipo_renta: 1, //1 corresponde a la mensual fija y 2 a mensual variable
        dia_inicio_periodo: 1,
        calle: '',
        numero_exterior: '',
        numero_interior: '',
        colonia: '',
        municipio: '',
        codigo_postal: '',
        estado_residencia: '',
        pais: '',
        id_cpe: null,
        id_router: null,
        id_isp: null,
        id_servicio: null,
        id_dh: null,
        carga_descarga_servicio: '',
        precio_servicio: 0,
        id_contrato_fisico: '',
        inicio_periodo: null,
        fin_periodo: null,
        cargo_morosidad: false,
        notificacion_correo: false, //notificacion por enviar
        control_manual_cpe: false,
        enlace_activo_cpe: false,
        servicios_complementarios: [],
        id_sitio: null,
        modo_generar_facturas: 1,
        prorroga: 0
      },
      contratoInicial: {},
      routers: [],
      isps: [],
      servicios: [],
      cpes: [],
      actualizarCpe: false,
      cpeSeleccionado: {
        id: null,
        ip_wan: '',
        id_router: null,
        id_isp: null,
        mac_wan: '',
        pppoe: 0,
        id_estado: null,
        id_marca: null,
        id_modelo: null,
        id_sitio: null,
        id_servicio: null,
        id_perfil_velocidad: null,
        control_manual: 0,
        enlace_activo: 0,
        nombre:'',
        marca: {
          nombre: ''
        },
        modelo: {
          identificador: ''
        }
      },

      estados_cpes: [],
      marcas_cpes: [],
      modelos_cpes:[],

      cpeSeleccionadoInicial: {},

      dhs: [],
      estados_dh:[],
      modelos_dh:[],
      tipos_dh:[],
      marcas_dh:[],
      
      actualizarDh: false,
      dhSeleccionado: {
        id: null,
        ip_wan: '',
        mac_wan: '',
        id_estado: null,
        id_marca: null,
        id_modelo: null,
        id_tipo: null,
        marca: {
          nombre: ''
        },
        modelo: {
          identificador: ''
        },
        tipo: {
          nombre: ''
        }
      },
      dhSeleccionadoInicial: {},

      servicioSeleccionado: {
        nombre: ''
      },
      servicioSeleccionadoInicial: {},
      soloLectura: true,
      posIniProm: null,
      sitios: [],
      bandera_spinner: false,

      serviciosPPPoE: [],
      puertas_enlace: '',
      /**
       * configuracionCpe es aquella sugerida al usuario administrador, para que pueda
       * configurar su CPE físicamente
       */
      configuracionCpe: {
        puerta_enlace: '',
        mascara_subred: '',
        cidr: null,
        dns1: '',
        dns2: ''
      },

      archivos: []
    }
  },

  created: function() {
    var self = this

    if (this.$route.path.indexOf('contratos/nuevo') >= 0) this.modo = 'nuevo'
    else {
      self.contrato.id = self.$route.params.id_contrato
      this.modo = 'edicion'
    }

    // Copia inicial del contrato
    self.contratoInicial = Object.assign({}, self.contrato)
    self.contratoInicial.servicios_complementarios = []
    self.cpeSeleccionadoInicial = Object.assign({}, self.cpeSeleccionado)
    self.dhSeleccionadoInicial = Object.assign({}, self.dhSeleccionado)
    self.servicioSeleccionadoInicial = Object.assign(
      {},
      self.servicioSeleccionado
    )

    // Cargas iniciales
    self.cargarCliente()

    // Cargas de sitios
    self.cargarSitios()
    self.iniciarMapa()

    if(self.modo == 'nuevo')
      self.cargarValorDiasAntesGenerarFacturas()

    // Cargas iniciales
    this.cargaPorcentajeIva()

    if (self.modo == 'nuevo') self.cargaInicialNuevoContrato()
    else self.cargaContratoAEditar()
  },

  mounted() {
    var self = this
  },

  computed: {
    ...todoGetters,
    apikeyGoogle() {
      return this.$store.state.todo.apiKeyGoogleMaps
    },
    fecha_pago() {
      return moment(this.contrato.fin_periodo, 'DD/MM/YYYY').add(this.contrato.dia_pago, 'days').format('DD/MM/YYYY')
    },
    fecha_corte_servicio() {
      return moment(this.fecha_pago, 'DD/MM/YYYY').add(this.contrato.n_dias_corte, 'days').format('DD/MM/YYYY')
    },
    fecha_corte_servicio_con_prorroga() {
      let diasASumar = this.contrato.n_dias_corte + this.contrato.prorroga
      return moment(this.fecha_pago, 'DD/MM/YYYY').add(diasASumar , 'days').format('DD/MM/YYYY')
    },
    fecha_generar_nueva_factura() {
      if(this.contrato.id_tipo_renta == 1)
        return moment(this.fecha_pago, 'DD/MM/YYYY').subtract(this.contrato.n_dias_generar_factura, 'days').format('DD/MM/YYYY')
      else
        return moment(this.contrato.fin_periodo, 'DD/MM/YYYY').subtract(this.contrato.n_dias_generar_factura, 'days').format('DD/MM/YYYY')
    },
    breadcrumb:function(){
      var self = this, 
        array = []
    
      let nombreCompletoCliente = (self.cliente.nombre || '')+' '+(self.cliente.apellido_paterno || '')+' '+(self.cliente.apellido_materno || '')

      array = [
        {
          titulo: 'Clientes',
          ruta: null,
          params: null
        },
        {
          titulo: nombreCompletoCliente,
          ruta: 'tableroCliente',
          params: { id_cliente: self.$route.params.id_cliente }
        },
        {
          titulo: 'Contrato de servicios',
          ruta: null,
          params: null
        },
        {
          titulo: self.modo == 'nuevo' ? 'Nuevo' : 'Edición',
          ruta: null,
          params: null
        }
      ]
      return array
    },
    modoTemplate: function() {
      return this.$store.state.layout.layoutMode
    },
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  watch: {
    'contrato.inicio_periodo' : function(newVal, oldVal) {
      var self = this

      if(self.contrato.fin_periodo == null || self.contrato.fin_periodo == '')
        self.contrato.fin_periodo = new Date(moment(newVal, 'DD/MM/YYYY').add(1, 'month').format())
    },
    dhSeleccionado: function(nvoDh, antDh) {
      if (nvoDh.id != null) this.ocuparDh(nvoDh.id)
      if (antDh.id != null) this.desocuparDh(antDh.id)
    }
  },

  methods: {
    actualizar() {
      var self = this,
        contrato = Object.assign({}, self.contrato)

      self.bandera_spinner = true

      if (contrato.id_cpe != null) {
        if (contrato.id_router == null) {
          iu.msg.warning('Es necesario seleccionar un router de administración')
          self.bandera_spinner = false
          return
        }

        if (contrato.id_isp == null) {
          iu.msg.warning('Es necesario seleccionar un ISP')
          self.bandera_spinner = false
          return
        }
      }

      self.guardarCPE(contrato)
    },

    actualizarContrato(periodo=null) {
      var self = this,
        contrato = Object.assign({}, self.contrato)

      contrato.fecha_instalacion = contrato.fecha_instalacion != null ? moment(contrato.fecha_instalacion, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      contrato.fecha_vencimiento = contrato.fecha_vencimiento != null ? moment(contrato.fecha_vencimiento, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''

      if(periodo != null){
        contrato.inicio_periodo = periodo.inicio != null ? moment(periodo.inicio, "YYYY-MM-DD").format('DD/MM/YYYY') : ''
        contrato.fin_periodo = periodo.fin != null ? moment(periodo.fin, "YYYY-MM-DD").format('DD/MM/YYYY') : ''
        self.contrato.inicio_periodo = periodo.inicio != null ? new Date(moment(periodo.inicio, "YYYY-MM-DD").format()) : ''
        self.contrato.fin_periodo = periodo.fin != null ? new Date(moment(periodo.fin, "YYYY-MM-DD").format()) : ''
      } else {
        contrato.inicio_periodo = contrato.inicio_periodo != null ? moment(contrato.inicio_periodo, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
        contrato.fin_periodo = contrato.fin_periodo != null ? moment(contrato.fin_periodo, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      }

      self.bandera_spinner = true

      // Validación de datos
      if (contrato.id_cpe != null) {
        if (contrato.id_router == null) {
          iu.msg.warning('Es necesario seleccionar un router de administración')
          self.bandera_spinner = false
          return
        }

        if (contrato.id_isp == null) {
          iu.msg.warning('Es necesario seleccionar un ISP')
          self.bandera_spinner = false
          return
        }
      }

      if (contrato.id_servicio == null) {
        iu.msg.warning('Es necesario seleccionar un servicio')
        self.$refs.id_servicio.focus()
        self.bandera_spinner = false
        return
      }

      if (contrato.fecha_instalacion == '') {
        iu.msg.warning('Es necesaria una fecha de instalación')
        self.bandera_spinner = false
        return
      }

      if (contrato.inicio_periodo == '') {
        iu.msg.warning('Es necesaria una fecha de perido inicial')
        self.bandera_spinner = false
        return
      }

      if (contrato.fin_periodo == '') {
        iu.msg.warning('Es necesaria una fecha de perido inicial')
        self.bandera_spinner = false
        return
      }

      ContratoSrv.actualizar(contrato).then(response => {
        iu.msg.success('Se actualizó correctamente el contrato')
        self.cargaContratoAEditar()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    actualizarCargaDescargaContrato() {
      var self = this

      if (self.contrato.id_servicio == null) return

      let index = this.servicios.findIndex(function(srv) {
        return srv.id == self.contrato.id_servicio
      })

      this.contrato.carga_descarga_servicio = `${
        self.servicios[index].velocidad_descarga
      }${self.servicios[index].unidad_descarga}/${
        self.servicios[index].velocidad_carga
      }${self.servicios[index].unidad_carga}`
    },

    actualizarCpeSeleccionado() {
      var self = this

      self.actualizarCpe = false

      if(self.contrato.id_cpe == -1) {
        self.cpeSeleccionado = Object.assign({}, {
          id: null,
          ip_wan: '',
          id_router: null,
          id_isp: null,
          mac_wan: '',
          pppoe: 0,
          id_estado: null,
          id_marca: null,
          id_modelo: null,
          id_sitio: null,
          id_servicio: null,
          id_perfil_velocidad: null,
          enlace_activo: 1,
          control_manual: 1,
          nombre:'',
          marca: {
            nombre: ''
          },
          modelo: {
            identificador: ''
          }
        })

        self.contrato.id_router = null
        self.contrato.id_isp = null
        self.bandera_spinner = false
        self.actualizarServicioSeleccionado()
        return
      }

      if (self.contrato.id_cpe == null) {
        self.cpeSeleccionado = Object.assign({}, {
          id: null,
          ip_wan: '',
          id_router: null,
          id_isp: null,
          mac_wan: '',
          pppoe: 0,
          id_estado: null,
          id_marca: null,
          id_modelo: null,
          id_sitio: null,
          id_servicio: null,
          id_perfil_velocidad: null,
          enlace_activo: 1,
          control_manual: 1,
          nombre:'',
          marca: {
            nombre: ''
          },
          modelo: {
            identificador: ''
          }
        })

        self.contrato.id_router = null
        self.contrato.id_isp = null
        self.cpeSeleccionado.id_servicio = null
        self.cpeSeleccionado.id_perfil_velocidad = null
        self.bandera_spinner = false
        return
      }

      let index = this.cpes.findIndex(function(cpe) {
        return cpe.id == self.contrato.id_cpe
      })
      this.cpeSeleccionado = this.cpes[index]

      // Actualización ID del router seleccionado en el contrato
      if (this.cpeSeleccionado.id_router != null) {
        this.contrato.id_router = this.cpeSeleccionado.id_router
        this.contrato.id_isp = this.cpeSeleccionado.id_isp
        this.actualizarIspsRouter(this.contrato.id_isp)
        self.cargarServiciosPPPoE()
        self.cargarPuertasEnlace(function() {
          self.actualizarConfiguracionIpParaCpe()
        })
      }

      if( self.contrato.id_servicio != null ) self.actualizarServicioSeleccionado()

      if( self.cpeSeleccionado.id_marca != null ) self.cargarModelosCpes()
    },

    actualizarDhSeleccionado() {
      var self = this
      
      self.actualizarDh = false
      // como el DH es opcional, es posible que el ID venga en NULL,
      // por lo cual se evita continuar el proceso de selección del DH
      if(self.contrato.id_dh == -1) {
        self.dhSeleccionado = Object.assign({}, {
          id: null,
          ip_wan: '',
          mac_wan: '',
          id_estado: null,
          id_marca: null,
          id_modelo: null,
          id_tipo: null,
          marca: {
            nombre: ''
          },
          modelo: {
            identificador: ''
          },
          tipo: {
            nombre: ''
          }
        })
        self.bandera_spinner = false
        return
      }

      if (self.contrato.id_dh == null) {
        self.dhSeleccionado = Object.assign({}, {
          id: null,
          ip_wan: '',
          mac_wan: '',
          id_estado: null,
          id_marca: null,
          id_modelo: null,
          id_tipo: null,
          marca: {
            nombre: ''
          },
          modelo: {
            identificador: ''
          },
          tipo: {
            nombre: ''
          }
        })
        self.bandera_spinner = false
        return
      }

      let index = this.dhs.findIndex(function(dh) {
        return dh.id == self.contrato.id_dh
      })
      this.dhSeleccionado = this.dhs[index]

      if(this.dhSeleccionado.id_marca != null) self.cargarModelosDH()
    },

    actualizarIspsRouter: function(idIspSeleccionado) {
      var self = this
      idIspSeleccionado = idIspSeleccionado || null

      self.bandera_spinner = true

      RouterSrv.ispsJSON(self.contrato.id_router).then(response => {
        self.isps = response.data

        // Selección del primer ISP
        if (idIspSeleccionado == null && self.isps.length > 0)
          self.contrato.id_isp = self.isps[0].id
          self.cpeSeleccionado.id_isp = self.isps[0].id
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    actualizarPrecioServicio: function() {
      var self = this
      if (self.contrato.id_servicio == null) return

      let index = this.servicios.findIndex(function(srv) {
        return srv.id == self.contrato.id_servicio
      })

      let servicio = self.servicios[index]

      this.contrato.precio_servicio = servicio.precio
      this.contrato.precio_servicio = parseFloat(
        this.contrato.precio_servicio
      ).toFixed(2)
    },

    actualizarPosicionMarcador: function() {
      let nvaPosicion = {
        lat: this.contrato.latitud,
        lng: this.contrato.longitud
      }
      self.marker.setPosition(nvaPosicion)
    },

    actualizarServicioSeleccionado: function() {
      var self = this

      var index = self.servicios.findIndex(function(srv) {
        return srv.id == self.contrato.id_servicio
      })

      if (index >= 0) {
        // En este punto, si se encontró el indice del servicio
        // a seleccionar se selecciona el servicio
        self.servicioSeleccionado = self.servicios[index]
        self.cpeSeleccionado.id_servicio = self.servicios[index].id
        self.cpeSeleccionado.id_perfil_velocidad = self.servicios[index].id_perfil_velocidad_cpes

        this.actualizarCargaDescargaContrato()
        this.actualizarPrecioServicio()
      } else if (self.contrato.id_servicio != null) {
        // Si no se encontró el servicio seleccionado, significa que
        // no está en la lista de servicios activos, debe de encontrarse
        // en la lista de servicios inactivos.
        // Se debe de realizar la carga por separado

        self.bandera_spinner = true

        ProductoSrv.planServicioJSON(self.contrato.id_servicio).then(response => {
          let servicio = response.data

          self.servicios.push(servicio)
          self.servicioSeleccionado =
            self.servicios[self.servicios.length - 1]

          self.actualizarCargaDescargaContrato()
          self.actualizarPrecioServicio()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = ''
          }
          mensaje != '' ? iu.msg.error(mensaje) : null
          console.log(error)
        }).finally(() => {
          self.bandera_spinner = false
        })
      }
    },

    atras: function() {
      this.$router.go(-1)
    },

    cancelarContrato: function() {
      var self = this

      ContratoSrv.cancelar(self.contrato.id).then(response => {
        iu.msg.success('Se canceló el contrato correctamente')
        self.atras()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cancelar el contrato'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarCliente: function() {
      var idCliente = this.$route.params.id_cliente,
        self = this

      self.bandera_spinner = true

      ClienteSrv.clienteJSON(idCliente).then(response => {
        self.cliente = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    cargarServiciosPPPoE: function() {
      var self = this

      // Si el CPE no tiene un router definido, evita continuar
      if (self.cpeSeleccionado.id_router == null) return

      RouterSrv.serviciosPppoeJSON(self.cpeSeleccionado.id_router).then(response => {
        self.serviciosPPPoE = response.data

        if (self.cpeSeleccionado.pppoe == true && self.serviciosPPPoE.length == 0) {
          iu.msg.warning(
            'No hay un servicio PPPoE disponible en el router para el CPE. ' +
              'Selecciona otro router o registra una LAN con el servicio PPPoE activado'
          )
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los servicios PPPoE'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPuertasEnlace: function(callback) {
      var self = this

      // Si el CPE no tiene definido un id de router, evita continuar
      if (self.cpeSeleccionado.id_router == null) {
        self.puertas_enlace = []
        if (callback) callback()
        return
      }

      RouterSrv.puertasEnlaceJSON(self.cpeSeleccionado.id_router).then(response => {
        self.puertas_enlace = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las puertas de enlace del router'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    actualizarConfiguracionIpParaCpe: function() {
      var self = this,
        ipcpe = self.cpeSeleccionado.ip_wan

      // Si tiene la bandera PPPoE activada se evita continuar
      if (self.cpeSeleccionado.pppoe == true) return

      // Si el CPE no tiene una dirección IP WAN registrada, se evita continuar
      if (ipcpe == null || ipcpe == '') return

      // Filtrado de la puerta de enlace compatible
      var puertaEnlace = self.puertas_enlace.find(function(pe) {
        let tmp = pe.split('/')

        // Si no tiene CIDR, evita continuar
        if (tmp[1] == undefined) return false
        let cidr = tmp[1]

        return (
          ipaddr.IPv4.networkAddressFromCIDR(pe).toString() ==
          ipaddr.IPv4.networkAddressFromCIDR(ipcpe + '/' + cidr).toString()
        )
      })

      let addr
      try {
        let tmp1 = puertaEnlace.split('/')
        addr = ipaddr.IPv4.parseCIDR(puertaEnlace)
        self.configuracionCpe.puerta_enlace = addr[0].toString()
        self.configuracionCpe.mascara_subred = ipaddr.IPv4.subnetMaskFromPrefixLength(
          addr[1]
        )
        self.configuracionCpe.cidr = tmp1[1]
      } catch (error) {
        if (self.cpeSeleccionado.id_router != null) {
          self.configuracionCpe.puerta_enlace = '- - Ninguna - -'
          self.configuracionCpe.mascara_subred = '- - Ninguna - -'
          iu.msg
            .warning(`El router seleccionado no cuenta con una puerta de enlace compatible con la dirección IP del CPE. 
                        Actualiza la dirección IP WAN del CPE o selecciona otro router de administración`)
        }
      }

      // Actualización de los DNS sugeridos
      // Se obtiene el router seleccionado
      var router = self.routers.find(function(rt) {
        return rt.id == self.cpeSeleccionado.id_router
      })

      if (router == null) {
        self.configuracionCpe.dns1 = 'No hay DNS sugerido'
        self.configuracionCpe.dns2 = 'No hay DNS sugerido'
        self.configuracionCpe.cidr = null
      } else {
        self.configuracionCpe.dns1 = router.dns1_sugerido
        self.configuracionCpe.dns2 = router.dns2_sugerido
      }
    },

    cargarValorDiasAntesGenerarFacturas() {
      let self = this
      SistemaSrv.valorAjuste('dias_antes_generar_facturas').then(response => {
        self.contrato.n_dias_generar_factura = response.data
      }).catch(error => {
        self.contrato.n_dias_generar_factura = 1
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la cantidad de dias antes de la fecha de pago para generar la factura'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargaInicialNuevoContrato: function() {
      var self = this
      let params

      // Asignación del ID de cliente
      self.contrato.id_cliente = this.$route.params.id_cliente

      // Definición de promesas
      var routersProm = RouterSrv.vrouters1JSON()

      routersProm.then(response => {
        // Registro de los routers
        self.routers = response.data

        // Si no hay routers, se evita continuar
        if (self.routers.length > 0) {
          // Selección del primer router
          self.contrato.id_router = self.routers[0].id
          self.cpeSeleccionado.id_router = self.routers[0].id
          self.cargarServiciosPPPoE()
          self.cargarPuertasEnlace(function() {
            self.actualizarConfiguracionIpParaCpe()
          })
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })

      var ispsProm = routersProm.then(response => {
        if(response.data.length > 0)
          return RouterSrv.ispsJSON(self.contrato.id_router)
      }).then(response => {
        if(response) self.isps = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })

      params = { paginacion: false, activo: true }
      var serviciosProm = ProductoSrv.planesServicioJSON(params)

      serviciosProm.then(response => {
        self.servicios = response.data

        // Selección del primer servicio
        if (self.servicios.length > 0) {
          self.contrato.id_servicio = self.servicios[0].id
          self.actualizarServicioSeleccionado()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })

      SistemaSrv.fecha({ formato: 'Y-m-d H:i:s' }).then(response => {
        self.contrato.fecha_instalacion = new Date(response.data)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })

      self.cpesDisponiblesParaContrato()
      self.cargarMarcasCpes()
      self.cargarEstadosCpes()
      self.cargarSitios()

      self.dhsDisponiblesParaContrato()
      self.cargarEstadosDH()
      self.cargarTiposDH()
      self.cargarMarcasDH()
    },

    cpesDisponiblesParaContrato:function(){
      var self = this,
      params = { con_marca: true, con_modelo: true }
      CpeSrv.disponiblesParaContratoJSON(params).then(response => {
        self.cpes = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarMarcasCpes: function(callback) {
      var self = this

      MarcaCpesSrv.marcasJSON().then(response => {
        self.marcas_cpes = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las marcas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarModelosCpes: function(callback) {
      var self = this
      let params = { id_marca: self.cpeSeleccionado.id_marca }

      ModeloCpesSrv.modelosJSON(params).then(response => {
        self.modelos_cpes = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los modelos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarEstadosCpes: function(callback) {
      var self = this
      EstadoCpesSrv.estadosJSON().then(response => {
        self.estados_cpes = response.data

        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los estados'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarEstadosDH: function(callback) {
      var self = this
      
      EstadoDhsSrv.estadosJSON().then(response => {
        self.estados_dh = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los estados'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarMarcasDH: function(callback) {
      var self = this
      
      MarcaDhsSrv.marcasJSON().then(response => {
        self.marcas_dh = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las marcas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarModelosDH: function(callback) {
      var self = this
      
      let params = { id_marca: self.dhSeleccionado.id_marca }

      ModeloDhsSrv.modelosJSON(params).then(response => {
        self.modelos_dh = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los modelos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarTiposDH: function() {
      var self = this

      TipoDhsSrv.tiposJSON().then(response => {
        self.tipos_dh = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los tipos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    dhsDisponiblesParaContrato: function() {
      var self = this,
      params = { con_marca: true, con_modelo: true, con_tipo: true }
      DhSrv.disponiblesParaContratoJSON(params).then(response => {
        self.dhs = response.data

        self.dhs.forEach(function(dh) {
          dh.ocupado = false
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarArchivosContrato: function(idContrato) {
      var self = this

      ArchivoContratosSrv.archivosJSON(idContrato).then(response => {
        self.archivos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los archivos del contrato'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    calcularSize: function(bytes) {
      var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
      for (var i = 0; i < sizes.length; i++) {
        if (bytes <= 1024) return bytes + sizes[i]
        else bytes = parseFloat(bytes / 1024).toFixed(2)
      }
      return bytes + ' P'
    },

    visualizarArchivo: function(archivo) {
      var self = this 
      self.$refs.mdlVisorArchivo.mostrar(archivo, self.contrato.id)
    },

    descargarArchivo: function(archivo) {
      var self = this
      let tk = localStorage.getItem('argusblack.token')
      window.open(`${self.API}/contratos/${self.contrato.id}/archivos/${archivo.nombre}/descargar?_tk=${tk}`, "_blank")
    },

    editarArchivo: function(archivo) {
      var self = this,
      indice = archivo.nombre.lastIndexOf('.'),
      nombre = archivo.nombre.substring(indice, 0)

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "¿Renombrar archivo?",
        html: `¿Está seguro que deseas renombrar el archivo <strong>${nombre}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.nombreArchivo(archivo)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    nombreArchivo: function(archivo){
      var self = this,
      indice = archivo.nombre.lastIndexOf('.'),
      nombre = archivo.nombre.substring(indice, 0)

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "Nuevo nombre del archivo",
        html: `Escriba el nuevo nombre del archivo <strong>"${nombre}"</strong> <br><br> El nuevo nombre debe de ir sin extensión`,
        input: "text",
        inputPlaceholder: "Nuevo nombre",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a"
      }).then(text => {
        if (text.value) {
          let params = { nuevo_nombre: text.value }

          ArchivoContratosSrv.actualizar(self.contrato.id, archivo.nombre, params).then(response => {
            swal.fire("Renombrado!", `Se ha renombrado el archivo <strong>"${nombre}"</strong> correctamente a <strong>${text.value}</strong>`, "success");
            self.cargarArchivosContrato(self.contrato.id)
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo cancelar el abono'
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
          })
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    eliminarArchivo: function(archivo) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminar archivo",
        html: '¿Está seguro que desea eliminar el archivo <strong>'+archivo.nombre+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          ArchivoContratosSrv.eliminar(self.contrato.id, archivo.nombre).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");

            self.cargarArchivosContrato(self.contrato.id)
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    cargaContratoAEditar: function() {
      var self = this,
        contratoProm,
        routersProm,
        cpesProm,
        params

      self.bandera_spinner = true

      // Carga de los CPEs
      params = { con_marca: true, con_modelo: true }
      cpesProm = CpeSrv.disponiblesParaContratoJSON(params)

      cpesProm.then(response => {
        self.cpes = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })

      self.bandera_spinner = true

      cpesProm.then(response => {
        self.bandera_spinner = true
        contratoProm = ContratoSrv.contratoJSON(self.contrato.id)
        contratoProm.then(response => {
          let contrato = response.data

          // Formateo de fechas
          contrato.fecha_instalacion = new Date(moment(contrato.fecha_instalacion, 'YYYY-MM-DD').format())
          contrato.fecha_vencimiento = contrato.fecha_vencimiento != null ? new Date(moment(contrato.fecha_vencimiento, 'YYYY-MM-DD').format()) : null
          contrato.inicio_periodo = new Date(moment(contrato.inicio_periodo, 'YYYY-MM-DD').format())
          contrato.fin_periodo = new Date(moment(contrato.fin_periodo, 'YYYY-MM-DD').format())
          contrato.fecha_para_pago = moment(contrato.fecha_para_pago, 'YYYY-MM-DD').format('DD/MM/YYYY')
          contrato.fecha_corte_servicio = moment(contrato.fecha_corte_servicio, 'YYYY-MM-DD').format('DD/MM/YYYY')
          contrato.fecha_generar_nueva_factura = moment(contrato.fecha_generar_nueva_factura, 'YYYY-MM-DD').format('DD/MM/YYYY')

          contrato.notificacion_correo = contrato.notificacion_correo ? true: false
          contrato.cargo_morosidad = contrato.cargo_morosidad ? true: false
          contrato.control_manual_cpe = contrato.control_manual_cpe ? true: false
          contrato.enlace_activo_cpe = contrato.enlace_activo_cpe ? true : false

          Object.assign(self.contrato, contrato)

          // Registro y selección del CPE del contrato
          let cpeContrato = Object.assign({}, self.contrato.cpe)

          // Registro del CPE del contrato en la lista
          self.cpes.push(cpeContrato)
          self.cpes.sort(function(cpe1, cpe2) {
            return cpe1.id - cpe2.id
          })
          self.cpes = self.cpes.concat(response.data)

          self.cargarArchivosContrato(contrato.id)

          this.actualizarCpeSeleccionado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = ''
          }
          mensaje != '' ? iu.msg.error(mensaje) : null
          console.log(error)
        }).finally(() => {
          self.bandera_spinner = false
        })

        self.bandera_spinner = true

        // Obtención de los routers
        contratoProm.then(response => {
          self.bandera_spinner = true
          routersProm = RouterSrv.vrouters1JSON()

          routersProm.then(response => {
            // Registro de los routers
            self.routers = response.data
          }).finally(() => {
            self.bandera_spinner = false
          })

          // Carga de los routers
          if (self.contrato.id_router != null) {
            self.bandera_spinner = true
            RouterSrv.ispsJSON(self.contrato.id_router).then(response => {
              self.isps = response.data
            }).finally(() => {
              self.bandera_spinner = false
            })
          }
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = ''
          }
          mensaje != '' ? iu.msg.error(mensaje) : null
          console.log(error)
        }).finally(() => {
          self.bandera_spinner = false
        })

        self.bandera_spinner = true

        // Carga de los planes de servicio
        contratoProm.then(response => {
          self.bandera_spinner = true
          ProductoSrv.planesServicioJSON({
            paginacion: false,
            activo: true
          }).then(response => {
            self.servicios = response.data
            self.actualizarServicioSeleccionado()
          }).finally(() => {
            self.bandera_spinner = false
          })
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = ''
          }
          mensaje != '' ? iu.msg.error(mensaje) : null
          console.log(error)
        }).finally(() => {
          self.bandera_spinner = false
        })

        self.bandera_spinner = true

        // Carga de los DHs
        DhSrv.disponiblesParaContratoJSON({
          con_marca: true,
          con_modelo: true,
          con_tipo: true
        }).then(response => {
          self.dhs = response.data
          self.dhs.forEach(function(dh) {
            dh.ocupado = false
          })

          self.bandera_spinner = true
          contratoProm.then(response => {
            // Registro y selección del DH del contrato
            let dhContrato = Object.assign({}, self.contrato.dh)
            dhContrato.ocupado = true

            // Registro del DH del contrato en la lista
            self.dhs.push(dhContrato)
            self.dhs.sort(function(dh1, dh2) {
              return dh1.id - dh2.id
            })

            self.actualizarDhSeleccionado()
          }).finally(() => {
            self.bandera_spinner = false
          })
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = ''
          }
          mensaje != '' ? iu.msg.error(mensaje) : null
          console.log(error)
        }).finally(() => {
          self.bandera_spinner = false
        })

        self.cargarEstadosDH()
        self.cargarTiposDH()
        self.cargarMarcasDH()

        // Actualiza la posición del marcador
        
        self.bandera_spinner = true
        contratoProm.then(response => {
          self.posicionInicial = {
            lat: parseFloat(self.contrato.latitud),
            lng: parseFloat(self.contrato.longitud)
          }

          self.lat_lng = parseFloat(self.contrato.latitud)+','+parseFloat(self.contrato.longitud)
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = ''
          }
          mensaje != '' ? iu.msg.error(mensaje) : null
          console.log(error)
        }).finally(() => {
          self.bandera_spinner = false
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })

      self.cargarMarcasCpes()
      self.cargarEstadosCpes()
      self.cargarSitios()
    },

    cargaPorcentajeIva: function() {
      var self = this

      self.bandera_spinner = true

      SistemaSrv.valorAjuste('iva').then(response => {
        self.piva = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    cargarSitios: function() {
      var self = this

      let params = { dato: self.dato }

      self.bandera_spinner = true

      SitioSrv.sitiosJSON(params).then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron refrescar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    cerrar: function() {
      this.atras()
    },

    desocuparDh: function(idDh) {
      let index = this.dhs.findIndex(function(dh) {
        return dh.id == idDh
      })

      // Si no encontró el dh, se evita continuar
      if (index == -1) return

      this.dhs[index].ocupado = false

      // Provoca la reactividad del componente a la actualización de la propiedad "ocupado"
      this.dhs[index] = Object.assign({}, this.dhs[index])
    },

    formatoFecha(fecha) {
      return moment(fecha, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },

    guardar: function() {
      var self = this,
        contrato = Object.assign({}, this.contrato)

      self.bandera_spinner = true

      // Validación de datos
      if (contrato.id_cpe != null) {
        if (contrato.id_router == null) {
          iu.msg.warning('Es necesario seleccionar un router de administración')
          self.bandera_spinner = false
          return
        }

        if (contrato.id_isp == null) {
          iu.msg.warning('Es necesario seleccionar un ISP')
          self.bandera_spinner = false
          return
        }
      }

      if (contrato.id_servicio == null) {
        iu.msg.warning('Es necesario seleccionar un servicio')
        self.$refs.id_servicio.focus()
        self.bandera_spinner = false
        return
      }

      if (contrato.fecha_instalacion == '') {
        iu.msg.warning('Es necesaria una fecha de instalación')
        self.bandera_spinner = false
        return
      }

      if (contrato.inicio_periodo == '') {
        iu.msg.warning('Es necesaria una fecha de perido inicial')
        self.bandera_spinner = false
        return
      }

      if (contrato.fin_periodo == '') {
        iu.msg.warning('Es necesaria una fecha de perido inicial')
        self.bandera_spinner = false
        return
      }

      self.guardarCPE(contrato)
    },

    guardarCPE: function(contrato) {
      var self = this

      self.bandera_spinner = true

      if(contrato.id_cpe == null) {
        self.guardarDH(contrato)
        self.bandera_spinner = false
        return
      }

      var cpe = Object.assign({}, self.cpeSeleccionado)

      if (cpe.pppoe == true) {
        if (cpe.usuario_pppoe == null || cpe.usuario_pppoe == '') {
          iu.msg.warning('Es necesario un usuario para la credencial PPPoE')
          self.$refs.usuario_pppoe.focus()
          self.bandera_spinner = false
          return
        }
        if (cpe.clave_pppoe == null || cpe.clave_pppoe == '') {
          iu.msg.warning('Es necesario una clave para la credencial PPPoE')
          self.$refs.clave_pppoe.focus()
          self.bandera_spinner = false
          return
        }
      }

      if (!self.datosCorrectos(cpe)) {
        self.bandera_spinner = false
        return
      }

      cpe.control_manual = self.contrato.control_manual_cpe
      cpe.enlace_activo = self.contrato.enlace_activo_cpe

      if(contrato.id_cpe == -1) {
        CpeSrv.guardar(cpe).then(response => {
          iu.msg.success('Nuevo CPE guardado')
          self.contrato.id_cpe = response.data
          self.cpesDisponiblesParaContrato()
          self.guardarDH(contrato)
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo guardar'
          }
          iu.msg.error(mensaje)
          console.log(error.response.data)
        }).finally(() => {
          self.bandera_spinner = false
        })
      }

      if(contrato.id_cpe != null && contrato.id_cpe != -1 && self.actualizarCpe == true) {
        CpeSrv.actualizar(cpe).then(response => {
          iu.msg.success('CPE actualizado correctamente')
          self.guardarDH(contrato)
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo actualizar'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      }

      if(contrato.id_cpe != null && contrato.id_cpe != -1 && self.actualizarCpe != true) {
        self.guardarDH(contrato)
        self.bandera_spinner = false
        return
      }
    },

    guardarDH: function(contrato) {
      var self = this
      var dh = Object.assign({}, self.dhSeleccionado)
      
      self.bandera_spinner = true

      if(contrato.id_dh == null) {
        if(self.modo == 'nuevo') self.guardarContrato()
        else self.actualizarContrato()
        
        self.bandera_spinner = false
        return
      }

      if(contrato.id_dh == -1){
        DhSrv.guardar(dh).then(response => {
          iu.msg.success('Nuevo DH guardado')
          // Limpieza del formulario
          self.contrato.id_dh = response.data
          self.dhsDisponiblesParaContrato()
          
          if(self.modo == 'nuevo') self.guardarContrato()
          else self.actualizarContrato()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch(e) {
            mensaje = 'No se pudo guardar el nuevo DH'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          self.bandera_spinner = false
        })
      }

      if(contrato.id_dh != null && contrato.id_dh != -1 && self.actualizarDh == true){
        DhSrv.actualizar(dh).then(response => {
          iu.msg.success('DH actualizado correctamente')
          
          if(self.modo == 'nuevo') self.guardarContrato()
          else self.actualizarContrato()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch(e) {
            mensaje = 'No se pudo actualizar'
          }
          iu.msg.error(mensaje)
          console.log(error)
        }).finally(() => {
          self.bandera_spinner = false
        })
      }

      if(contrato.id_dh != null && contrato.id_dh != -1 && self.actualizarDh != true){
        if(self.modo == 'nuevo') self.guardarContrato()
        else self.actualizarContrato()
        self.bandera_spinner = false
        return
      }
    },

    guardarContrato: function() {
      var self = this,
        contrato = Object.assign({}, this.contrato)

      contrato.fecha_instalacion = contrato.fecha_instalacion != null ? moment(contrato.fecha_instalacion, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      contrato.fecha_vencimiento = contrato.fecha_vencimiento != null ? moment(contrato.fecha_vencimiento, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      contrato.inicio_periodo = contrato.inicio_periodo != null ? moment(contrato.inicio_periodo, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      contrato.fin_periodo = contrato.fin_periodo != null ? moment(contrato.fin_periodo, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''

      self.bandera_spinner = true
      
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      ContratoSrv.guardar(contrato).then(response => {
        let idContrato = response.data
        iu.msg.success('Contrato guardado correctamente')
        self.contrato = Object.assign({}, self.contratoInicial)
        self.cpeSeleccionado = Object.assign({}, self.cpeSeleccionadoInicial)
        self.dhSeleccionado = Object.assign({}, self.dhSeleccionadoInicial)
        self.servicioSeleccionado = Object.assign(
          {},
          self.servicioSeleccionadoInicial
        )

        swal.fire({
          title: "Generación de factura",
          html: '¿Desea generar la primera factura para el contrato registrado?',
          icon: "question",
          confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
          cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
          showCancelButton: true
        }).then(result => {
          if (result.value) {
            ContratoSrv.generarFactura(idContrato).then(response => {
              swal.fire("Generado!", "Se creó correctamente la factura", "success")
              self.atras()
            }).catch(error => {
              let mensaje
              try {
                mensaje = error.response.data.message
              } catch (e) {
                mensaje = 'No se pudo crear la factura'
              }
              iu.msg.error(mensaje)
              console.log(error)
            })
          } else if ( result.dismiss === Swal.DismissReason.cancel ) {
            self.atras()
          }
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el contrato'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    seleccionarArchivos: function() {
      var self = this

      if(self.modo == 'nuevo') {
        iu.msg.warning("No se pueden agregar archivos hasta que el contrato haya sido guardado primero.")
        return
      }

      var input = document.createElement("input")
      input.setAttribute('type', 'file')
      input.setAttribute('multiple', '')

      input.click()
      input.addEventListener('change', function(e) {
        var inputArchivos = e.target

        for(var i = 0; i < inputArchivos.files.length; i++) {
          let archivo = {
            nombre: inputArchivos.files[i].name,
            size: inputArchivos.files[i].size,
            file: inputArchivos.files[i],
          }
          self.archivos.push(archivo)
        }

        self.guardarArchivos(self.archivos)
      })
    },

    guardarArchivos(archivos = []) {
      var self = this, datos = { archivos: archivos }

      ArchivoContratosSrv.guardar(self.contrato.id, datos).then(response => {
        let archivos = response.data

        let mensaje = "Se han guardado correctamente los archivos"
        if(archivos.length != 0){
          mensaje += "<br><br><ul>"
          archivos.forEach(ar =>{
            mensaje += "<li>" + ar + "</li>"
          })
          mensaje += "</ul>"
        }

        iu.msg.success(mensaje, { width: 600 })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron guardar los archivos del contrato'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.cargarArchivosContrato(self.contrato.id)
      })
    },

    datosCorrectos: function(cpe) {
      var self = this,
        regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;

      if (cpe.nombre == '') {
        iu.msg.warning('Es necesario un nombre de CPE')
        self.$refs.nombre.focus()
        self.bandera_spinner = false
        return false
      }

      if (cpe.id_estado == null) {
        iu.msg.warning('Es necesario seleccionar un estado de CPE')
        self.$refs.id_estado.focus()
        self.bandera_spinner = false
        return false
      }

      if (cpe.id_marca == null) {
        iu.msg.warning('Es necesario seleccionar una marca de CPE')
        self.$refs.id_marca.focus()
        self.bandera_spinner = false
        return false
      }

      if (cpe.id_modelo == null) {
        iu.msg.warning('Es necesario seleccionar un modelo de CPE')
        self.$refs.id_modelo.focus()
        self.bandera_spinner = false
        return false
      }

      if (cpe.pppoe == false && cpe.ip_wan == '') {
        iu.msg.warning('Es necesario registrar una IP WAN válida para el CPE')
        self.$refs.ip_wan.focus()
        self.bandera_spinner = false
        return false
      }

      if(cpe.pppoe == false && cpe.ip_wan.length > 15){
        iu.msg.warning('La direccion IP del puerto WAN esta mal escrita verifique que ingreso correctamente la direccion IP')
        self.$refs.ip_wan.focus()
        self.bandera_spinner = false
        return false
      }

      if(cpe.ip_lan != '' && cpe.ip_lan != null && cpe.ip_lan.length > 15){
        iu.msg.warning('La direccion IP del puerto LAN esta mal escrita verifique que ingreso correctamente la direccion IP')
        self.$refs.ip_lan.focus()
        self.bandera_spinner = false
        return false
      }

      if(cpe.mac_wan != '' && cpe.mac_wan != null){
        // if(!regex.test(cpe.mac_wan)){
        //   iu.msg.warning('La direccion MAC del puerto WAN esta mal escrita verifique que ingreso correctamente la direccion MAC del CPE')
        //   self.$refs.mac_wan.focus()
        //   return false
        // }
        if(cpe.mac_wan.length > 17){
          iu.msg.warning('La direccion MAC del puerto WAN esta mal escrita verifique que ingreso correctamente la direccion MAC del CPE')
          self.$refs.mac_wan.focus()
          self.bandera_spinner = false
          return false
        }
      }

      if(cpe.mac_lan != '' && cpe.mac_lan != null){
        // if(!regex.test(cpe.mac_lan)){
        //   iu.msg.warning('La dirección MAC del puerto LAN esta mal escrita verifique que ingreso correctamente la direccion MAC para el CPE')
        //   self.$refs.mac_lan.focus()
        //   return false
        // }
        if(cpe.mac_lan.length > 17){
          iu.msg.warning('La dirección MAC del puerto LAN esta mal escrita verifique que ingreso correctamente la direccion MAC para el CPE')
          self.$refs.mac_lan.focus()
          self.bandera_spinner = false
          return false
        }
      }

      try {
        if (
          cpe.ip_wan != '' &&
          cpe.ip_lan != '' &&
          self.configuracionCpe.cidr != null &&
          self.configuracionCpe.cidr != ''
        ) {
          var net1 = ipaddr.IPv4.networkAddressFromCIDR(
              self.cpe.ip_wan + '/' + self.configuracionCpe.cidr
            ),
            net2 = ipaddr.IPv4.networkAddressFromCIDR(
              self.cpe.ip_lan + '/' + self.configuracionCpe.cidr
            )

          if (net1.toString() == net2.toString()) {
            iu.msg.error(
              'El segmento de red de la dirección IP LAN debe ser diferente al segmento de red de la dirección IP WAN del CPE'
            )
            self.$refs.ip_lan.focus()
            self.bandera_spinner = false
            return false
          }
        } else {
          var net1 = ipaddr.IPv4.networkAddressFromCIDR(
              self.cpe.ip_wan + '/24'
            ),
            net2 = ipaddr.IPv4.networkAddressFromCIDR(self.cpe.ip_lan + '/24')

          if (net1.toString() == net2.toString()) {
            iu.msg.warning(
              'Es muy probable que la dirección IP WAN y LAN estén en el mismo segmento. Revisa que los datos sean correctos.'
            )
            self.$refs.ip_lan.focus()
            self.bandera_spinner = false
          }
        }
      } catch (error) {}

      if (cpe.id_router != null) {
        if (cpe.id_isp == null) {
          iu.msg.warning('Es necesario seleccionar un proveedor de internet para el CPE')
          self.$refs.id_isp.focus()
          self.bandera_spinner = false
          return false
        }

        if (cpe.id_perfil_velocidad == null) {
          iu.msg.warning('Es necesario seleccionar un perfil de velocidad para el CPE')
          // self.$refs.id_perfil_velocidad.focus()
          self.bandera_spinner = false
          return false
        }
      }

      return true
    },

    iniciarMapa: function() {
      var self = this

      SistemaSrv.posicionInicial().then(response => {
        let posicion = response.data

        self.posicionInicial = {
          lat: parseFloat(posicion.latitud),
          lng: parseFloat(posicion.longitud)
        }

        self.lat_lng = parseFloat(posicion.latitud)+','+parseFloat(posicion.longitud)

        if (self.modo == 'nuevo') {
          self.contrato.latitud = parseFloat(posicion.latitud)
          self.contrato.longitud = parseFloat(posicion.longitud)

          self.lat_lng = parseFloat(posicion.latitud)+','+parseFloat(posicion.longitud)
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    mostrarMdlCambiarPerido: function(){
      var self = this, idContrato = self.contrato.id
      
      self.$refs.mdlCamPer.mostrar(idContrato)
    },

    mostrarMdlHistorialPeriodos: function(){
      var self = this, idContrato = self.contrato.id
      
      self.$refs.mdlHistPer.mostrar(idContrato)
    },

    mostrarMdlThroughPut: function(idCpe) {
      var self = this

      self.$refs.mdlthroughput.mostrar(idCpe)
    },

    mostrarPosicion: function(event) {
      var self = this 

      self.contrato.latitud = event.latLng.lat()
      self.contrato.longitud = event.latLng.lng()

      self.posicionInicial = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }

      self.lat_lng = event.latLng.lat()+','+event.latLng.lng()
    },

    actualizarPosicion: function() {
      var self = this, array = self.lat_lng.split(',')

      if(array.length == 1 || array.length > 2) {
        iu.msg.warning("No es una coordenada válida, ingresé una coordenada correcta.")
        self.lat_lng = parseFloat(self.contrato.latitud)+','+parseFloat(self.contrato.longitud)
        return
      }
      let latitud = parseFloat(array[0]), longitud = parseFloat(array[1])

      if(!latitud || !longitud) {
        iu.msg.warning("Latitud o longitud no son coordenadas válidas; ingresé una coordenada correcta.")
        self.lat_lng = parseFloat(self.contrato.latitud)+','+parseFloat(self.contrato.longitud)
        return
      }

      self.contrato.latitud = latitud
      self.contrato.longitud = longitud

      self.posicionInicial = {
        lat: latitud,
        lng: longitud
      }

      self.lat_lng = latitud+','+longitud
    },

    ocuparDh: function(idDh) {
      let index = this.dhs.findIndex(function(dh) {
        return dh.id == idDh
      })

      this.dhs[index].ocupado = true

      // Provoca la reactividad del componente a la actualización de la propiedad "ocupado"
      this.dhs[index] = Object.assign({}, this.dhs[index])
    },

    preguntaCancelarContrato: function() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Cancelación de contrato",
        html: `¿Está seguro de cancelar el contrato ${self.contrato.id}?`,
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          ContratoSrv.cancelar(self.contrato.id).then(response => {
            swal.fire("Cancelado!", "Se canceló el contrato correctamente", "success");
            self.atras()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo cancelar el contrato'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    tipoRentaActualizada: function() {
      var self = this
      if (self.contrato.id_tipo_renta == 2) {
        self.contrato.prepago = 1
        self.contrato.modo_generar_facturas = 1
      }
    },

    usarDireccionCliente: function() {
      var self = this

      ClienteSrv.direccionJSON(self.contrato.id_cliente).then(response => {
        Object.assign(self.contrato, response.data)

        let latitud = parseFloat(response.data.latitud != null ? response.data.latitud : 0)
        let longitud = parseFloat(response.data.longitud != null ? response.data.longitud : 0)

        self.contrato.latitud = latitud
        self.contrato.longitud = longitud

        self.posicionInicial = {
          lat: latitud,
          lng: longitud
        }
  
        self.lat_lng = latitud+','+longitud
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    }
  }
}