<template>
  <div class="modal fade" ref="mdlCambiarPeriodo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <i class="mdi mdi-calendar"></i>
            Actualizar periodo de contrato
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <label for="">Motivo del cambio</label>
              <select ref="motivo" class="form-select" v-model="tipo_motivo">
                <option :value="0">Seleccionar</option>
                <option :value="1">Contratación de servicio</option>
                <option :value="2">Factura manual</option>
                <option :value="3">Otro</option>
              </select>
            </div>
            <div class="col-md-4">
              <label>Periodo inicio</label>
              <Datepicker
                ref="fecha_inicial"
                v-model="fecha_inicial"
                placeholder="dd/mm/aaaa"
                :class="'form-control bg-white'"
                :inputFormat="'dd/MM/yyyy'"
              />
            </div>
            <div class="col-md-4">
              <label>Periodo fin</label>
              <Datepicker
                ref="fecha_final"
                v-model="fecha_final"
                placeholder="dd/mm/aaaa"
                :class="'form-control bg-white'"
                :inputFormat="'dd/MM/yyyy'"
              />
            </div>
          </div>
          <br>
          <div class="row" v-show="tipo_motivo == 3">
            <div class="col-md-12">
              <label>Nota:</label>
              <textarea
                class="form-control"
                rows="5"
                v-model="motivo"
              ></textarea>
              <br>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="cerrar()"
          >
            Cerrar
          </button>
          <button
            class="btn btn-success"
            @click="actualizarPeriodo()"
            :disabled="bandera_spinner"
          >
            <i
              class="mdi"
              :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
            ></i>
            Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Modal from 'bootstrap/js/dist/modal';
import ContratoSrv from '@/services/ContratoSrv.js'
import moment from 'moment'

import Datepicker from 'vue3-datepicker'

export default {
  name: 'MdlCambiarPeriodo',
  components: { Datepicker },
  data() {
    return {
      id_contrato: null,
      tipo_motivo: 0,          // 1.-Contratación de servicio, 2.-Factura manual, 3.-Otro
      contrato: {
        id: null,
        ultimo_periodo: {
          id:null
        }
      },
      periodo: {
        id: null,
        id_contrato: null,
        id_factura: null,
        inicio: "",
        fin: "",
        motivo: null
      },
      motivo: '',
      fecha_inicial: null,
      fecha_final: null,
      bandera_spinner: false
    }
  },
  watch: {
    fecha_inicial : function(newVal, oldVal) {
      var self = this
      self.fecha_final = new Date(moment(newVal, 'DD/MM/YYYY').add(1, 'month').format())
    }
  },
  methods: {
    actualizarPeriodo(){
      var self = this

      self.bandera_spinner = true

      let periodo_inicial = moment(self.periodo.inicio).unix()
      let periodo_final = moment(self.periodo.fin).unix()

      let fecha_inicial = moment(self.fecha_inicial,'DD/MM/YYYY').format("YYYY-MM-DD")
      fecha_inicial = moment(fecha_inicial).unix()

      let fecha_final = moment(self.fecha_final,'DD/MM/YYYY').format("YYYY-MM-DD")
      fecha_final = moment(fecha_final).unix()

      if(self.tipo_motivo == 0){
        iu.msg.warning("No se ha seleccionado un motivo del cambio")
        self.$refs.motivo.focus()
        self.bandera_spinner = false
        return
      }

      if(periodo_inicial == fecha_inicial && periodo_final == fecha_final){
        iu.msg.warning("No se ha detectado un cambio en el periodo inicial o final")
        self.bandera_spinner = false
        return
      }
      

      var idContrato = self.id_contrato
      var periodo = {
        inicio: moment(self.fecha_inicial,'DD/MM/YYYY').format("YYYY-MM-DD"),
        fin: moment(self.fecha_final,'DD/MM/YYYY').format("YYYY-MM-DD"),
        id_contrato: idContrato,
        id_factura: null
      }
      
      let nMotivo 
      switch (self.tipo_motivo) {
        case 1: 
          nMotivo = 'Contratación de servicio' 
        break;
        case 2:
          nMotivo = 'Factura manual' 
        break;
        case 3:
          nMotivo = self.motivo
        break;
      }
      
      Object.assign(periodo, { motivo: nMotivo })

      ContratoSrv.guardarPeriodo(idContrato, periodo).then(response => {
        self.$emit('periodo:actualizado', periodo)
        iu.msg.success('El periodo se actualizó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el nuevo periodo del contrato ' + idContrato
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
        self.cerrar()
      })
    },

    cerrar() {
      var self = this

      self.id_contrato = null
      self.tipo_motivo = 0          // 1.-Contratación de servicio, 2.-Factura manual, 3.-Otro
      self.contrato = {
        id: null,
        ultimo_periodo: {
          id:null
        }
      }
      self.periodo = {
        id: null,
        id_contrato: null,
        id_factura: null,
        inicio: "",
        fin: "",
        motivo: null
      }
      self.motivo = ''
      self.fecha_inicial = null
      self.fecha_final = null
      self.bandera_spinner = false
      
      var modal = Modal.getInstance(self.$refs.mdlCambiarPeriodo)
      modal.hide()
    },

    cargarUltimoPeriodo() {
      let self = this,
        params = {
          limite: 1,
          orden: 'desc'
        }

      ContratoSrv.periodosContratoJSON(self.id_contrato, params).then(response => {
        let periodos = response.data
        
        if(periodos.length > 0)
          self.periodo = Object.assign({}, periodos[0])
        
        self.fecha_inicial = new Date( moment(self.periodo.inicio, 'YYYY-MM-DD').format() )
        self.fecha_final = new Date( moment(self.periodo.fin, 'YYYY-MM-DD').format() )
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el último periodo del contrato ' + self.id_contrato
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    formatoFecha(mt, formato = 'DD/MM/YYYY') {
      return moment(mt).format(formato)
    },

    mostrar: function(idContrato) {
      var self = this
      
      self.id_contrato = idContrato
      self.cargarUltimoPeriodo()

      var modal = new Modal(self.$refs.mdlCambiarPeriodo)
      modal.show()
    }
  },
}
</script>