<template>
  <div class="modal fade" ref="MdlVisorArchivo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-info">
          <h5 class="modal-title text-white">
            Visor del archivo ({{ archivo.nombre }})
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="archivo.type.indexOf('text') == -1 && archivo.type.indexOf('image') == -1">
            <embed
              :type="archivo.type.indexOf('image') == -1 ? archivo.type : ''"
              :src="ruta"
              style="width: 100%; height: 600px;"
            />
          </div>
          <div v-if="archivo.type.indexOf('image') != -1" class="text-center">
            <SimpleBar
              data-simplebar
              style="max-height: 600px;"
            >
              <img :src="ruta" class="img-fluid rounded img-thumbnail"/>
            </SimpleBar>
          </div>
          <div v-if="archivo.type.indexOf('text') != -1">
            <textarea 
              :value="text" 
              class="form-control"
              rows="30"
              disabled
              :class="{
                'bg-white': $store.state.layout.layoutMode == 'light',
              }"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArchivoContratosSrv from '@/services/ArchivoContratosSrv.js'
import Modal from 'bootstrap/js/dist/modal';
import API from "@/API.js"
import { SimpleBar } from "simplebar-vue3"
export default {
  name: 'MdlVisorArchivo',
  components: { SimpleBar },
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      id_contrato: null,
      ruta: null,
      archivo: {
        nombre: '',
        size: 0,
        type: ''
      },
      text: ''
    }
  },
  methods: {
    cargarVisor: function() {
      var self = this

      ArchivoContratosSrv.visor(self.id_contrato, self.archivo.nombre).then(response => {
        self.text = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    mostrar(archivo, id_contrato) {
      var self = this

      self.id_contrato = null
      self.archivo.nombre = ''
      self.archivo.size = 0
      self.archivo.type = ''
      self.ruta = null
      self.text = ''

      self.id_contrato = id_contrato
      archivo.type = archivo.type !== false ? archivo.type : 'false'
      self.archivo = Object.assign(self.archivo, archivo)

      self.ruta = `${self.API}/contratos/${self.id_contrato}/archivos/${self.archivo.nombre}/visor?_tk=${self.tk}`

      if(archivo.type.indexOf('text') != -1) {
        self.cargarVisor()
      }

      var modal = new Modal(this.$refs.MdlVisorArchivo)
      modal.show()
    }
  },
}
</script>

<style scoped>
</style>