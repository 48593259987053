<template>
  <div class="card">
    <div class="card-header justify-content-between d-flex align-items-center">
      <h4 class="card-title">Facturación</h4>
      <div>
        <div class="dropdown">
          <a
            class="dropdown-toggle"
            href="javascript: void(0);"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <eva-icon
              name="more-horizontal-outline"
              data-eva-width="20"
              data-eva-height="20"
              :class="{
                'fill-dark': $store.state.layout.layoutMode == 'light',
                'fill-white': $store.state.layout.layoutMode == 'dark'
              }"
            ></eva-icon>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li>
              <a
                href="#"
                class="dropdown-item"
                @click="modo == 'nuevo' ? guardar() : actualizar()"
                onclick="return false;"
                v-show="!contratoCancelado && !bandera_spinner"
              >
                <i class="mdi mdi-content-save text-success"></i>
                {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
              </a>
              <span class="dropdown-item disabled" v-if="bandera_spinner">
                <i class="mdi mdi-concourse-ci mdi-spin"></i>
                {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
              </span>
            </li>
            <li>
              <a
                class="dropdown-item" href="#"
                onclick="return false;" @click="cerrar()"
              >
                <i class="mdi mdi-close text-dark"></i>
                Cerrar
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-hover align-middle table-nowrap">
              <thead>
                <tr>
                  <th style="min-width: 200px">Servicio principal</th>
                  <th>Descripción</th>
                  <th style="width: 150px">Up/Down</th>
                  <th style="width: 100px">Precio</th>
                  <th style="width:150px" v-show="!contratoCancelado"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="servicioPrincipal.id != null">
                  <td>{{ servicioPrincipal.nombre }}</td>
                  <td>
                    {{ servicioPrincipal.descripcion }}
                  </td>
                  <td>
                    {{ servicioPrincipal.velocidad_carga }}
                    {{ servicioPrincipal.unidad_carga }} /
                    {{ servicioPrincipal.velocidad_descarga }}
                    {{ servicioPrincipal.unidad_descarga }}
                  </td>
                  <td class="text-right">
                    {{ fmon(precioServicioPrincipal, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                  </td>
                  <td v-show="!contratoCancelado"></td>
                </tr>
              </tbody>
            </table>
          </div>

          <br />
          <hr />

          <div class="text-right">
            <button
              class="btn btn-outline-success btn-sm"
              title="Nuevo servicio complemetario a facturar"
              @click="nuevoServicio()"
              v-show="!contratoCancelado"
            >
              <i class="fa fa-plus"></i>
              Nuevo servicio
            </button>
          </div>

          <div class="table-responsive">
            <table class="table table-hover align-middle table-nowrap">
              <thead>
                <tr>
                  <th style="width: 200px">Servicio complementario</th>
                  <th>Descripción</th>
                  <th style="width: 150px">Dispositivo de hogar</th>
                  <th style="width: 100px">Precio</th>
                  <th style="width: 100px;" v-show="!contratoCancelado">
                    Acción
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(servicio, index) in serviciosComplementarios">
                  <tr v-if="true" :key="`servicio-${index}`">
                    <td>{{ servicio.nombre_servicio }}</td>
                    <td>{{ servicio.descripcion_servicio }}</td>
                    <td>{{ servicio.nombre_dh }}</td>
                    <td class="text-right">{{ fmon(servicio.precio, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</td>
                    <td v-show="!contratoCancelado">
                      <button
                        class="btnBorrar btn btn-light btn-sm"
                        @click="borrarServicio(index)"
                      >
                        <i class="fa fa-trash"></i>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                  <tr v-if="servicio.nota != null && servicio.nota != ''" :key="`serv-${index}`">
                    <td></td>
                    <td colspan="3">
                      <pre>{{ servicio.nota }}</pre>
                    </td>
                    <td></td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="offset-md-9 col-md-3">
          <div class="p-sm-3 bg-light">
            <table class="table align-middle table-nowrap">
              <tr>
                <td>Sub total</td>
                <td class="text-right">{{ fmon(subTotal, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</td>
              </tr>
              <tr>
                <td>Impuestos</td>
                <td class="text-right">{{ fmon(iva, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td class="text-right">{{ fmon(total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!-- <br> -->

      <!-- <div class="row mb-4">
        <label class="offset-md-6 col-md-5 p-2 text-right">timbrado de facturas</label>
        <div class="col-md-1 text-right">
          <div class="form-check form-switch form-check-right form-switch-lg">
            <input
              class="form-check-input"
              type="checkbox" id="timbrado-facturas"
              checked=""
            />
            <label class="form-check-label" for="timbrado-facturas">
            </label>
          </div>
        </div>
      </div> -->
    </div>

    <mdl-servicio ref="mdlServicio" :dhs="dhs" v-on:agregar-servicio="agregarServicio($event)">
    </mdl-servicio>
  </div>
</template>

<script>
import MdlServicio from './MdlServicio.vue'
import DhSrv from '@/services/DhSrv.js'
export default {
  name: 'PnlFacturacion',
  components: { MdlServicio },
  props: [
    'modo',
    'servicioPrincipal', // Servicio principal
    'precioServicioPrincipal', // Precio del servicio principal
    'contratoCancelado', // Bandera que indica al componente si el contrato está cancelado
    'serviciosComplementarios', // Lista de servicios complementarios asignados al contrato
    'dhs', // Dispositivos de Hogar
    'piva', // Porcentaje de IVA
    'bandera_spinner' //bandera de spinner
  ],
  data() {
    return {
      fmon: iu.hrr.fmon,
      subTotal: 0,
      iva: 0,
      total: 0
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  mounted: function() {
    this.actualizarResultadosFacturacion()
  },
  watch: {
    servicioPrincipal: function(val) {
      this.actualizarResultadosFacturacion()
    },
    serviciosComplementarios: function(val) {
      var self = this
      // Verifica que el DH exista en la lista de dhs, de lo contrario, lo manda a cargar
      this.serviciosComplementarios.forEach(function(sc) {
        // Si el servicio complementario no tiene asignado un dh, se evita continuar
        if (sc.id_dh == null) return

        let index = self.dhs.findIndex(function(dh) {
          return dh.id == sc.id_dh
        })

        // Si existe se evita continuar
        if (index > -1) return

        // Si no existe el indice, se manda a cargar el DH
        DhSrv.dhJSON(sc.id_dh).then(response => {
          let dhstmp = self.dhs
          let dhtmp = response.data
          dhtmp.ocupado = true
          dhstmp.push(dhtmp)

          dhstmp.sort(function(dh1, dh2) {
            return dh1.id - dh2.id
          })

          self.$emit('update:dhs', dhstmp)
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = ''
          }
          mensaje != '' ? iu.msg.error(mensaje) : null
          console.log(error)
        })
      })

      this.actualizarResultadosFacturacion()
    }
  },
  methods: {
    actualizar: function() {
      this.$emit('actualizar')
    },

    actualizarResultadosFacturacion() {
      // Actualización de resultados de facturación
      this.actualizarSubTotal()
      this.actualizarImpuestos()
      this.actualizarTotal()
    },
    actualizarSubTotal: function() {
      var self = this
      this.subTotal = 0
      this.subTotal += this.servicioPrincipal.precio

      // Suma de los precios de los servicios complementarios
      this.serviciosComplementarios.forEach(function(sc) {
        self.subTotal += parseFloat(sc.precio)
      })
    },
    actualizarImpuestos: function() {
      var self = this
      self.iva = (self.subTotal / 100) * self.piva
    },
    actualizarTotal: function() {
      var self = this
      self.total = self.subTotal + self.iva
    },
    agregarServicio: function(servicio) {
      var scs = this.serviciosComplementarios.slice(0)
      scs.push(servicio)

      this.$emit('update:serviciosComplementarios', scs)

      if (servicio.id_dh != null) this.$emit('dh-ocupado', servicio.id_dh)
    },
    borrarServicio: function(index) {
      var self = this
      let servicio = self.serviciosComplementarios[index]
      let scs = self.serviciosComplementarios

      if (servicio.id_dh != null) self.$emit('dh-desocupado', servicio.id_dh)

      var array = []
      scs.forEach((sc, i) => {
        if(i != index) array.push(sc)
      })
      
      this.$emit('update:serviciosComplementarios', array)
    },
    cerrar: function() {
      this.$emit('cerrar')
    },
    guardar: function() {
      this.$emit('guardar')
    },
    nuevoServicio: function() {
      var self = this
      self.$refs.mdlServicio.mostrar()
    }
  }
}
</script>

<style scoped>
</style>