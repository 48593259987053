<template>
  <div class="modal fade" ref="MdlThroughPut" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xxl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Grafica de consumo
            <div style="min-height: 30px; display: inline-block">
              <img 
                :src="API +'/cpes/modelos/'+cpe.id_modelo+'/imagen?tipo_foto=64x64&_tk='+tk"
                v-if="cpe.id != null && cpe.id != 'null' && cpe.modelo.id_foto != null && cpe.modelo.id_foto != 'null'"
                class="iconcpe"
              />
            </div>
            <span
              class="text-success"
              v-if="cpe.en_linea == true"
              title="Conectado"
            >
              <i class="fa fa-signal"></i>
            </span>
            <span
              class="text-danger"
              v-if="cpe.en_linea == false"
              title="Desconectado"
            >
              <i class="fa fa-exclamation-circle"></i>
            </span>
            &nbsp;
            <strong>{{cpe.nombre}}</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cerrar()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-6 mb-3">
              <div class="input-group">
                <span class="input-group-text" title="Mostar datos">
                  <span class="d-none d-sm-block">
                    Mostar datos
                  </span>
                  <span class="d-block d-sm-none">
                    Datos
                  </span>
                </span>
                <select
                  class="form-select"
                  v-model="mostrar_tipo"
                  ref="id_interfaz"
                  v-on:click="dibujarGrafica()"
                >
                  <option :value="1">Mostrar ambos</option>
                  <option :value="2">Transferencia (tx)</option>
                  <option :value="3">Recepción (rx)</option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-6 mb-3">
              <div class="input-group">
                <span class="input-group-text" title="Tráfico por">
                  <span class="d-none d-sm-block">
                    Tráfico por
                  </span>
                  <span class="d-block d-sm-none">
                    Por
                  </span>
                </span>
                <select
                  class="form-select"
                  v-model="tipo_trafico"
                  ref="tipoTrafico"
                  v-on:click="cargarTrafico()"
                >
                  <option value="hora">Hora</option>
                  <option value="dia">Día</option>
                  <option value="semana">Semana</option>
                  <option value="mes">Mes</option>
                  <option value="trimestre">Trimestre</option>
                  <option value="anio">Año</option>
                </select>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-6 mt-2 text-center mb-3">
              <span
                class="badge font-size-14 badge-transferencia me-2 mb-2"
                title="Transferencia (tx)"
                v-if="mostrar_tipo == 2 || mostrar_tipo == 1"
              >
                <span class="d-none d-sm-block">
                  Transferencia (tx)
                </span>
                <span class="d-block d-sm-none">
                  Tx
                </span>
              </span>
              <span
                class="badge font-size-14 badge-recepcion"
                title="Recepción (rx)"
                v-if="mostrar_tipo == 3 || mostrar_tipo == 1"
              >
                <span class="d-none d-sm-block">
                  Recepción (rx)
                </span>
                <span class="d-block d-sm-none">
                  Rx
                </span>
              </span>
            </div>

            <div class="col-lg-3 col-md-6 col-6 mb-3 text-right">
              <button
                class="btn btn-light"
                @click="reiniciarTraficoCpe()"
                :disabled="cpe.reiniciar_trafico"
              >
                <span class="d-none d-sm-block">
                  <i class="mdi mdi-cloud-sync"></i>
                  Reiniciar tráfico
                </span>
                <span class="d-block d-sm-none">
                  <i class="mdi mdi-cloud-sync"></i>
                  Reiniciar
                </span>
              </button>
            </div>
          </div>
          <br>
          <apexchart
            ref="traficoCpe"
            class="apex-charts"
            height="380"
            dir="ltr"
            :series="series"
            :options="chartOptions"
          ></apexchart>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="cerrar()"
          >
            <i class="mdi mdi-times"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
import CpeRouterSrv from '@/services/trafico/CpeRouterSrv.js'
import CpeSrv from '@/services/CpeSrv.js'
import Swal from 'sweetalert2'
import moment from 'moment'
import API from '@/API.js'

export default {
  name: 'MdlThroughput',

  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      cpe: {
        id: null,
        id_router: null,
        nombre: '',
        en_linea: false,
        id_modelo: null,
        modelo: {
          id_foto: null
        }
      },
      id_cpe: null,
      traficos: [],
      mostrar_tipo: 1,
      intervals: {
        actualizacion: null
      },
      tipo_trafico: 'hora',
      series: [
        {
          name: "",
          data: [],
        }
      ],
      // configuracion de la grafica
      chartOptions: {
        chart: {
          height: 360,
          type: "area",
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: true,
          },
        },
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#18cc93","#32cbfd"],
        title: {
          text: "",
          align: "left",
          style: {
            fontWeight: 500,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: [],
          tickAmount: 9,
          tooltip: {
            enabled: false,
          }
        }
      }
    }
  },
  created: function() {
    var self = this

    self.intervals.actualizacion = setInterval(function() {
      if(self.id_cpe != null && self.cpe.id_router != null) self.cargarTrafico()
    }, 300000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    cargarCpe: function() {
      var self = this

      CpeSrv.cpeJSON(self.id_cpe, { con_modelo: true }).then(response => {
        let cpe = response.data
        Object.assign(self.cpe, cpe)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el CPE'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.cargarTrafico()
      })
    },
    cargarTrafico: function() {
      var self = this,
          params = {
            id_router: self.cpe.id_router,
            tipo_trafico: self.tipo_trafico
          }

      self.traficos = []

      CpeRouterSrv.traficoJSON(self.id_cpe, params).then(response => {
        let traficos = response.data

        traficos.forEach(function(t) {
          let formato='',           formato2=''
          // 'YYYY-MM-DD HH:mm:ss' 'DD/MM/YYYY'
          switch(self.tipo_trafico) {
            case 'hora':
              formato = 'HH:mm'
              formato2 = 'HH:mm'
            break;
            case 'dia':
           
              formato = 'DD HH:mm'
              formato2 = 'ddd D[-]HH:mm [hrs]'
            break;
            case 'semana':
              formato = 'DD HH'
              formato2 = 'ddd D[-]HH [hrs]'
            break;
            case 'mes':
              formato = 'YYYY-MM-DD'
              formato2 = 'DD/MM/YYYY'
            break;
            case 'trimestre':
              formato = 'YYYY-MM-DD'
              formato2 = 'DD/MM/YYYY'
            break;
            case 'anio':
              formato = 'YYYY-MM-DD'
              formato2 = 'DD/MM/YYYY'
            break;
          }
          Object.assign(t,{time: self.formatoHora(t.tiempo, formato, formato2)})
          self.traficos.push(t)
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar el trafico de la interfaz'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
       self.dibujarGrafica()
      })
    },
    formatoHora(tiempo, formato='HH:mm', formato2='HH:mm:ss') {
      return moment(tiempo, formato).locale('es-mx').format(formato2)
    },
    cerrar: function() {
      var self = this

      self.id_cpe = null
      self.cpe.id_router = null
      self.trafico = []
      clearInterval(this.intervals.actualizacion)
      var modal = Modal.getInstance(this.$refs.MdlThroughPut)
      modal.hide()
    },
    dibujarGrafica(t) {
      var self = this

      let tx = []
      let rx = []
      let labels = []

      self.traficos.forEach((trafico) => {
        tx.push((trafico.tx_bytes*0.000001).toFixed(3))
        rx.push((trafico.rx_bytes*0.000001).toFixed(3))
        labels.push(trafico.time)
      })

      var series= []
      var colors= []

      switch (self.mostrar_tipo) {
        case 1:
          series = [
            {
              name: "Transferencia (tx)",
              data: tx
            },{
              name: 'Recepción (rx)',
              data: rx
            }
          ]
          colors = ["#18cc93","#32cbfd"]
        break;
        case 2:
          series = [
            {
              name: 'Transferencia (tx)',
              data: tx
            }
          ]
          colors = ["#18cc93"]
        break;
        case 3:
          series = [
            {
              name: 'Recepción (rx)',
              data: rx
            }
          ]
          colors = [ "#32cbfd" ]
        break;
      }

      self.$refs.traficoCpe.updateSeries(series, false)

      self.$refs.traficoCpe.updateOptions({
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: true,
          },
        },
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: colors,
        title: {
          text: "Consumo en tiempo real",
          align: "left",
          style: {
            fontWeight: 500,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: labels,
          tickAmount: 10,
          tooltip: {
            enabled: false,
          }
        },
      })
    },
    mostrar: function(idCpe) {
      var self = this
      
      self.id_cpe = idCpe
      self.traficos = []
      // self.dibujarGrafica()
      self.cargarCpe()

      var modal = new Modal(self.$refs.MdlThroughPut)
      modal.show()
    },
    reiniciarTraficoCpe() {
      var self = this,
          params = { id_router: self.cpe.id_router }

      if(self.cpe.reiniciar_trafico) {
        iu.msg.warning("No se puede reiniciar el tráfico red LAN, ya que se encuentra reiniciando el tráfico de la red LAN")
        return
      }

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Reiniciar el tráfico del CPE",
        html: '¿Está seguro que desea reiniciar el tráfico del CPE para <strong>'+self.cpe.nombre +'</strong>? <br><br>'+
              'Esto reiniciará todo el tráfico por hora, día, semana, mes, trimestre y año. <br><br>'+
              'Eliminando todo el tráfico registrado actualmente.',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          CpeRouterSrv.reiniciarTrafico(self.cpe.id, params).then(response => {
            swal.fire("Reiniciado!", "Se ha reiniciado el tráfico correctamente del CPE "+self.cpe.nombre, "success");
            self.cargarTrafico()
            self.cerrar()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudio reiniciar el tráfico del CPE'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  }
}
</script>

<style scoped>
.badge-transferencia {
  background-color: #1ACC93;
  color: white;
}
.badge-recepcion {
  background-color: #32CBFD;
  color: white;
}
.iconcpe {
  width:30px;
  height:30px;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>