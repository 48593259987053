<template>
  <div class="modal fade" ref="MdlThroughPut" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Grafica de consumo en tiempo real
            <div style="min-height: 30px; display: inline-block">
              <img 
                :src="API +'/cpes/modelos/'+cpe.id_modelo+'/imagen?tipo_foto=64x64&_tk='+tk"
                v-if="cpe.id != null && cpe.id != 'null' && cpe.modelo.id_foto != null && cpe.modelo.id_foto != 'null'"
                class="iconcpe"
              />
            </div>
            <span
              class="text-success"
              v-if="cpe.en_linea == true"
              title="Conectado"
            >
              <i class="fa fa-signal"></i>
            </span>
            <span
              class="text-danger"
              v-if="cpe.en_linea == false"
              title="Desconectado"
            >
              <i class="fa fa-exclamation-circle"></i>
            </span>
            &nbsp;
            <strong>{{cpe.nombre}}</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cerrar()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <div class="input-group">
              <span class="input-group-text">
                Mostar datos
              </span>
              <select
                class="form-select"
                v-model="mostrar_tipo"
                ref="id_interfaz"
                v-on:click="dibujarGrafica()"
              >
                <option :value="1">Mostrar ambos</option>
                <option :value="2">Transferencia (tx)</option>
                <option :value="3">Recepción (rx)</option>
              </select>
              </div>
            </div>

            <div class="col-md-6 mt-2 text-center">
              <span class="badge font-size-16 badge-transferencia" v-if="mostrar_tipo == 2 || mostrar_tipo == 1">Transferencia (tx)</span>
              <span class="badge font-size-16 badge-recepcion" v-if="mostrar_tipo == 3 || mostrar_tipo == 1">Recepción (rx)</span>
            </div>
          </div>

          <br>

          <apexchart
            class="apex-charts"
            height="380"
            dir="ltr"
            :series="series"
            :options="chartOptions"
          ></apexchart>
          
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="cerrar()"
          >
            <i class="mdi mdi-times"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
import CpeSrv from '@/services/CpeSrv.js'
import API from '@/API.js'
export default {
  name: 'MdlThroughput',

  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      cpe: {
        id: null,
        nombre: '',
        en_linea: false,
        id_modelo: null,
        modelo: {
          id_foto: null
        }
      },
      id_cpe: null,
      trafico: [
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" }
      ],
      mostrar_tipo: 1,
      intervals: {
        actualizacion: null
      },
      series: [
        { name: "", data: [] }
      ],
      // configuracion de la grafica
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#18cc93","#32cbfd"],
        title: {
          text: "",
          align: "left",
          style: {
            fontWeight: 500,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: [
            // "Ene",
            // "Feb",
            // "Mar",
            // "Apr",
            // "May",
            // "Jun",
            // "Jul",
            // "Aug",
            // "Sep",
            // "Oct",
            // "Nov",
            // "Dic"
          ],
          tickAmount: 10,
          tooltip: {
            enabled: false,
          }
        }
      }
    }
  },
  created: function() {
    var self = this
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    cargarCpe: function() {
      var self = this

      CpeSrv.cpeJSON(self.id_cpe, { con_modelo: true }).then(response => {
        let cpe = response.data
        Object.assign(self.cpe, cpe)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el CPE'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarTrafico: function() {
      var self = this

      if(self.id_cpe == null) {
        self.traficos = [
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" },
          { rx_bps:0, tx_bps:0, time:"00:00:00" }
        ]
        return
      }

      CpeSrv.traficoJSON(self.id_cpe).then(response => {
        let trafico = response.data
        var time = new Date()
        Object.assign(trafico,{
          time: time.getHours()+':'+time.getMinutes()+':'+time.getSeconds()
        })
        self.traficos.push(trafico)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar el trafico de la interfaz'
        }
        // iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.dibujarGrafica()
      })
    },

    cerrar: function() {
      var self = this

      self.id_cpe = null
      self.trafico = []
      clearInterval(this.intervals.actualizacion)
      var modal = Modal.getInstance(this.$refs.MdlThroughPut)
      modal.hide()
    },

    dibujarGrafica() {
      var self = this
      
      let tx = []
      let rx = []
      let labels = []

      if(self.traficos.length > 15) self.traficos.shift()

      self.traficos.forEach((trafico,index) => {
        tx.push((trafico.tx_bps*0.000001).toFixed(3))
        rx.push((trafico.rx_bps*0.000001).toFixed(3))
        labels.push(trafico.time)
      })

      var series= []
      var colors= []

      switch (self.mostrar_tipo) {
        case 1:
          series = [
            { name: "Transferencia (tx)", data: tx },
            {
              name: 'Recepción (rx)',
              data: rx
            }
          ]
          colors = ["#18cc93","#32cbfd"]
        break;
        case 2:
          series = [
            {
              name: 'Transferencia (tx)',
              data: tx
            }
          ]
          colors = ["#18cc93"]
        break;
        case 3:
          series = [
            {
              name: 'Recepción (rx)',
              data: rx
            }
          ]
          colors = [ "#32cbfd" ]
        break;
      }

      self.series = series
      self.chartOptions = {
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: colors,
        title: {
          text: "Consumo en tiempo real",
          align: "left",
          style: {
            fontWeight: 500,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: labels,
          tickAmount: 10,
          tooltip: {
            enabled: false,
          }
        },
      }
    },
    mostrar: function(idCpe) {
      var self = this
      
      self.id_cpe = idCpe
      self.traficos = [
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" },
        { rx_bps:0, tx_bps:0, time:"00:00:00" }
      ]
      self.dibujarGrafica()
      self.cargarCpe()

      self.intervals.actualizacion = setInterval(function() {
        self.cargarTrafico()
      }, 5000)

      var modal = new Modal(self.$refs.MdlThroughPut)
      modal.show()
    }
  },
}
</script>

<style scoped>
.badge-transferencia {
  background-color: #1ACC93;
  color: white;
}
.badge-recepcion {
  background-color: #32CBFD;
  color: white;
}
.iconcpe {
  width:30px;
  height:30px;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>