<template>
  <div class="modal fade" ref="mdlHistorialPeriodo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <i class="mdi mdi-history"></i>
            Historial de periodos
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="min-height: 475px;">
          <div class="table-responsive" style="min-height: 475px;">
            <table class="table table-hover align-middle table-nowrap" id="tabla-historial-periodos">
              <thead>
                <tr>
                  <th style="width: 180px;">Fecha de cambio</th>
                  <th>Motivo de cambio</th>
                  <th style="width: 180px;" class="text-center">Periodo registrado</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(periodo,index) in periodos" :key="`periodo-${index+1}`">
                  <td>{{formatoFecha(periodo.created_at,'dddd, DD/MM/YYYY')}}</td>
                  <td>
                    <p class="text-truncate font-size-14" style="width: 600px;" :title="periodo.motivo">
                      {{periodo.motivo}}
                    </p>
                  </td>
                  <td>
                    {{formatoFecha(periodo.inicio)}}
                    <strong>al</strong>
                    {{formatoFecha(periodo.fin)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
import ContratoSrv from '@/services/ContratoSrv.js'
import moment from 'moment'
export default {
  name: 'MdlHistorialPeriodos',
  data() {
    return {
      id_contrato: null,
      periodos: [],
    }
  },

  methods: {
    cargarPeriodosContrato(){
      var self = this,
      params = {
        limite : 10,
        orden: "desc"
      }

      iu.spinner.mostrar('#tabla-historial-periodos')

      ContratoSrv.periodosContratoJSON(self.id_contrato, params).then(response => {
        self.periodos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los periodos del contrato' + self.id_contrato
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-historial-periodos')
      })
    },

    cancelar: function() {
      let self = this
      var modal = Modal.getInstance(self.$refs.mdlHistorialPeriodo)
      modal.hide()
    },

    formatoFecha(mt, formato = 'DD/MM/YYYY') {
      return moment(mt).locale('es-mx').format(formato)
    },

    palancaMostrarMotivo: function(cambio) {
      if (cambio.mostrar_motivo) cambio.mostrar_motivo = false
      else cambio.mostrar_motivo = true
    },

    mostrar: function(idContrato) {
      var self = this
      
      self.id_contrato = idContrato
      
      if(idContrato != null) self.cargarPeriodosContrato()
      
      var modal = new Modal(self.$refs.mdlHistorialPeriodo)
      modal.show()
    }
  },
}
</script>

<style scoped>

</style>