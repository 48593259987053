<template>
  <div class="modal fade" ref="mdlServicio" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Nuevo servicio
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="servcomp.id_servicio == null">
            <filtrador
              placeholder="Dato del servicio a filtrar"
              :datoini="dato"
              v-on:dato-actualizado="
                dato = $event,
                refrescarServicios()
              "
            ></filtrador>
            <div class="table-responsive">
              <table id="tblListaServ" class="table table-hover align-middle table-nowrap">
                <thead>
                  <tr>
                    <th style="width: 60px">ID</th>
                    <th>Nombre</th>
                    <th style="width: 100px">Precio</th>
                    <th style="width: 140px">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="servicio in servicios" :key="servicio.id">
                    <td>{{ servicio.id }}</td>
                    <td>{{ servicio.nombre }}</td>
                    <td>{{ fmon(servicio.precio, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</td>
                    <td>
                      <button
                        class="btn btn-light btn-sm"
                        @click="seleccionar(servicio)"
                      >
                        <i class="mdi mdi-check-bold"></i>
                        Seleccionar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- Filtrado del servicio a contratar -->

          <!-- Configuración del servicio a contratar -->
          <div v-if="servcomp.id_servicio != null">
            <div class="row">
              <div
                :class="{
                  'col-md-4': servcomp.categoria.necesita_dh,
                  'col-md-8': !servcomp.categoria.necesita_dh
                }"
                v-if="servcomp.categoria != undefined"
              >
                <label>Servicio</label>
                <input
                  type="text"
                  v-model="servcomp.nombre_servicio"
                  readonly
                  class="form-control"
                />
              </div>
              <div class="col-md-4">
                <label>Precio</label>
                <div class="input-group">
                  <span class="input-group-text">
                    {{ monedaSistema.simbolo }}
                  </span>
                  <input
                    type="number"
                    class="form-control text-right"
                    v-model="servcomp.precio"
                    placeholder="0"
                  />
                  <span class="input-group-text">
                    {{ monedaSistema.codigo }}
                  </span>
                </div>
              </div>
              <div
                class="col-md-4"
                v-if="
                  servcomp.categoria != undefined &&
                  servcomp.categoria.necesita_dh
                "
              >
                <label>Dispositivo de hogar</label>
                <select class="form-select" v-model="servcomp.id_dh">
                  <option
                    v-for="(dh, index) in dhsaux"
                    :key="`dh-${index+1}`"
                    :value="dh.id"
                    v-show="!dh.ocupado"
                  >
                    {{ dh.nombre }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label>Nota</label>
                <textarea
                  rows="5"
                  v-model="servcomp.nota"
                  maxlength="2000"
                  class="form-control"
                  placeholder="Nota del servicio"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- Fin de la configuración del servicio a contratar -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            <i class="mdi mdi-close"></i>
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-light"
            v-if="servcomp.id_servicio != null"
            @click="atras()"
          >
            <i class="mdi mdi-chevron-left"></i>
            Atrás
          </button>
          <button
            type="button"
            class="btn btn-success"
            v-if="servcomp.id_servicio != null"
            @click="agregar()"
          >
            <i class="mdi mdi-plus-thick"></i>
            Agregar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';

import Filtrador from '@/components/Filtrador.vue'
import ProductoSrv from '@/services/ProductoSrv.js'
export default {
  name: 'MdlServicio',
  props: ['servicioAEditar', 'dhs', 'modo'],
  components: { Filtrador },
  data() {
    return {
      fmon: iu.hrr.fmon,
      // Servicio complementarios
      servcomp: {
        id: null,
        id_contrato: null,
        id_servicio: null,
        id_dh: null,
        precio: 0,
        nota: ''
      },
      servcompini: {},
      dato: '',
      servicios: [],
      dhsaux: []
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  watch: {
    dhs: function(dhsActualiados) {
      this.dhsaux = dhsActualiados
    }
  },
  created: function() {
    var self = this

    // Actualización del servicio complementario inicial
    this.servcompini = Object.assign({}, this.servcomp)

    this.dhsaux = this.dhs
  },
  methods: {
    agregar: function() {
      let servicio = Object.assign({}, this.servcomp)

      if(servicio.id_servicio == null) {
        iu.msg.warning("No se ha seleccionado ningún servicio complementario, vuélvalo a intentar.")
        this.limpiar()
        return
      }

      var idDh = servicio.id_dh

      let dh = this.dhs.find(function(dh) {
        return dh.id == idDh
      })

      if (dh == undefined) dh = null

      Object.assign(servicio, {
        id_dh: dh != null ? dh.id : null,
        nombre_dh: dh != null ? dh.nombre : ''
      })

      this.$emit('agregar-servicio', servicio)
      this.limpiar()

      var modal = Modal.getInstance(this.$refs.mdlServicio)
      modal.hide()
    },

    atras: function() {
      this.servcomp = Object.assign({}, this.servcompini)
    },

    limpiar: function(e) {
      var self = this

      self.servcomp = Object.assign({}, self.servcompini)
      self.servcomp.id_dh = null
    },

    refrescarServicios: function() {
      var self = this

      iu.spinner.mostrar('#tblListaServ')

      if (self.dato == undefined || self.dato == '') {
        iu.msg.warning('Es necesario un dato del servicio')
        iu.spinner.ocultar('#tblListaServ')
        return
      }

      var params = { dato: self.dato }
      ProductoSrv.serviciosJSON(params).then(response => {
        self.servicios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      }).finally (fin => {
        iu.spinner.ocultar('#tblListaServ')
      })
    },

    seleccionar: function(servicio) {
      this.servcomp = {
        id_servicio: servicio.id,
        nombre_servicio: servicio.nombre,
        descripcion_servicio: servicio.descripcion,
        precio: servicio.precio,
        nota: servicio.nota,
        categoria: servicio.categoria
      }
    },
    mostrar() {
      var self = this
      
      const modal = new Modal(self.$refs.mdlServicio)
      modal.show()
    },
  },
}
</script>

<style scoped>

</style>