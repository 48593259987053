import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/contratos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(idContrato, nombre, params) {
    return apiClient.post(`${idContrato}/archivos/${nombre}`, params)
  },

  archivosJSON(idContrato) {
    return apiClient.get(`${idContrato}/archivos.json`)
  },

  eliminar(idContrato, nombre){
    return apiClient.delete(`${idContrato}/archivos/${nombre}`)
  },

  guardar(idContrato, datos) {
    let formData = new FormData(),
        headers = { headers: { 'Content-Type': 'multipart/form-data' } }

    datos.archivos.forEach(function(ar,index) {
      // Se registran las nuevas fotos a guardar en el servidor
      if (ar.file != null && ar.file != undefined) 
        formData.append('archivos[]', ar.file)
    })

    return apiClient.post(`${idContrato}/archivos`, formData, headers)
  },

  visor(idContrato, nombre) {
    return apiClient.get(`${idContrato}/archivos/${nombre}/visor`)
  }
}
